import React from "react"
import WiderrufsbelehrungContent from "../components/WiderrufsbelehrungContent"
import Layout from "../components/Layout"
import Section from "../components/Section"

export interface WiderrufsbelehrungProps {}

const Widerrufsbelehrung: React.SFC<WiderrufsbelehrungProps> = () => {
  return (
    <Layout>
      <Section>
        <WiderrufsbelehrungContent />
      </Section>
    </Layout>
  )
}

export default Widerrufsbelehrung
