import { Link } from "gatsby"
import React from "react"
import { H1 } from "./text/H1"
import { H2 } from "./text/H2"
import { LinkType, OutsideLink } from "./text/OutsideLink"
import { Paragraph } from "./text/Paragraph"

export interface WiderrufsbelehrungContentProps {}

const WiderrufsbelehrungContent: React.SFC<WiderrufsbelehrungContentProps> = () => {
  return (
    <>
      <H1 text="Widerrufsbelehrung" />
      <H2 text="Widerrufsrecht" />
      <Paragraph>
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen.
      </Paragraph>
      <Paragraph>
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie oder ein
        von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in
        Besitz genommen haben bzw. hat.
      </Paragraph>
      <Paragraph>
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, DPTA Die Psychotherapie
        App UG (haftungsbeschränkt), Kroatengasse 3, 97070 Würzburg,
        Deutschland, Telefonnummer: Auf Anfrage, E-Mail-Adresse:
        kontakt@diepsychotherapieapp.de mittels einer eindeutigen Erklärung
        (z.B. ein mit der Post versandter Brief, E-Mail) über Ihren Entschluss,
        diesen Vertrag zu widerrufen, informieren. Sie können dafür das
        beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
        vorgeschrieben ist.
      </Paragraph>
      <Paragraph>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
      </Paragraph>
      <H2 text="Folgen des Widerrufs" />
      <Paragraph>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet. Haben Sie verlangt, dass
        die Dienstleistungen während der Widerrufsfrist beginnen sollen, so
        haben Sie uns seinen angemessenen Betrag zu zahlen, der dem Anteil der
        bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits
        erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag
        vorgesehenen Dienstleistungen entspricht.
      </Paragraph>
      <H2 text="Muster-Widerrufsformular" />
      <Paragraph>
        (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
        Formular aus und senden Sie es zurück.)
      </Paragraph>
      <Paragraph>
        Die Psychotherapie App UG (haftungsbeschränkt) i. L.
        <br />
        Jonas Herrmannsdörfer
        <br />
        Kroatengasse 3, 97070 Würzburg, Deutschland
        <br />
        <OutsideLink
          href="jonas@diepsychotherapieapp.de"
          type={LinkType.mail}
        />
        <br />
        <Link
          className="text-primary-600 hover:text-primary-800 no-underline"
          to="/"
        >
          www.diepsychotherapieapp.de
        </Link>
      </Paragraph>
      <Paragraph>
        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
        Vertrag über die Erbringung der folgenden Dienstleistung:
        <br />
        ___________________________________________________________________________
        <br />
        ___________________________________________________________________________
        <br />
        ___________________________________________________________________________
      </Paragraph>
      <Paragraph>
        Bestellt am:
        <br />
        Erhalten am:
        <br />
        Name des/der Verbraucher(s):
        <br />
        Anschrift des/der Verbraucher(s):
      </Paragraph>
      <Paragraph>
        ___________________________________
        <br />
        Unterschrift (nur bei Mitteilung auf Papier)
      </Paragraph>
      <Paragraph>
        ___________________________________
        <br />
        Ort, Datum
      </Paragraph>
      <Paragraph>(*) Unzutreffendes streichen.</Paragraph>
    </>
  )
}

export default WiderrufsbelehrungContent
